import type { RegisterFormType } from './registerUtils';
import type { BooleanInputGroup, InputGroup, TranslationKey } from '../../../types/modulesInterfaces';
import { getRegisterInputGroups, initialValues, validationSchema } from './registerUtils';
import { useEffect, useState } from 'react'
import { Formik, Form, useFormikContext } from "formik";
import { FieldType } from '../../../types/modulesInterfaces';
import { useRegisterMutation } from '../../../app/features/auth/authApiSlice';
import { Link, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { selectCurrentForm, setForm, clearForm } from "../../../app/features/register/registerSlice"
import { EventTypes, useFirebaseHook } from '../../../utils/firebaseUtils';
import GenericInputGroup from '../../../components/input/inputGroup/GenericInputGroup';
import ModuleButton from '../../../components/button/ModuleButton';
import useErrorMessage from '../../../utils/useErrorMessage';
import BackToLoginButton from '../../../components/button/BackToLoginButton';
import ConfirmationModal from '../../../components/modal/ConfirmationModal';
import TranslatableText from '../../../components/translations/TranslatableText';

const Register = () => {
  return (
    <>
      <section className="d-flex f-column p-2">
        <h3
          className="fs-13 ff-black-ext pb-1 mb-1 w-fit"
          style={{ borderBottom: "8px solid var(--tertiary-bright)" }}
        >
          <TranslatableText translationKey="main.tool_name" />
        </h3>
      </section>
      <RegisterForm />
    </>
  )
}

const agreeToTerms: BooleanInputGroup = {
  type: FieldType.BOOLEAN,
  inputName: "isAgreeToTerms",
  label: "",
};

const agreeToGDPR: BooleanInputGroup = {
  type: FieldType.BOOLEAN,
  inputName: "isAgreeToGDPR",
  label: "",
};

const RegisterForm = () => {
  const [formInputGroups, setFormInputGroups] = useState<InputGroup[]>([])
  const [register, { isLoading, isError, error, isSuccess }] = useRegisterMutation()

  const dispatch = useAppDispatch()
  const { form } = useAppSelector(selectCurrentForm)
  const [initValues, setInitValues] = useState<RegisterFormType>(form ?? initialValues)
  const { errorMsg } = useErrorMessage({ isError, error })
  const { fireEvent } = useFirebaseHook();

  const handleSubmit = async (values: RegisterFormType) => {
    try {
      await register(values).unwrap()
      fireEvent(EventTypes.sign_up)
      //TODO: add error handling if registration fails
      dispatch(clearForm())
      setInitValues(initialValues)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    const res = getRegisterInputGroups()
    setFormInputGroups(res)
  }, [])

  return (
    <div className="d-flex f-column pt-1">
      <section className='login-form-container pb-2'>
        <h3 className="ff-medium fs-13 text-black pb-2">Create EX-ACT account</h3>
        <Formik initialValues={initValues} validationSchema={validationSchema} onSubmit={handleSubmit} enableReinitialize>
          <Form className="me-2 pe-3">
            <RegisterFormContent inputGroups={formInputGroups} isLoading={isLoading} errorMsg={errorMsg} />
          </Form>
        </Formik>
        {
          isSuccess ? <RegisterConfirmationModal /> : null
        }
      </section>
    </div>
  )
}

//TODO: normalize modal usage and add clickable outside button
const RegisterConfirmationModal = () => {
  const navigate = useNavigate()
  const [isModalOpen, setIsModalOpen] = useState<boolean>(true)
  const handleConfirmation = () => {
    setIsModalOpen(false)
    navigate('../login')
  }

  return (
    <ConfirmationModal
      onConfirm={handleConfirmation}
      open={isModalOpen}
      message={<TranslatableText translationKey="main.check_email_registration" />}
      confirmationMessage='main.back_to_logn'
      showCancel={false}
    />
  )
}

interface RegisterFormContentProps {
  inputGroups: InputGroup[],
  isLoading: boolean,
  errorMsg?: string,
}

const RegisterFormContent = ({ inputGroups, isLoading, errorMsg }: RegisterFormContentProps) => {
  const { values } = useFormikContext<RegisterFormType>();
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(setForm(values))
  }, [values, dispatch])
  return (
    <>
      {inputGroups.map((input) => (
        <div className="py-1" key={input.label}>
          <GenericInputGroup inputGroup={input} />
        </div>
      ))}

      <div className='d-flex f-column gap-8 py-1'>
        <div className='w-100 d-flex f-column'>
          {/*  */}
          <span className='fs-13 text-darker-gray'>Please agree to the
            <Link to={"../terms-and-conditions/terms"} relative="path" className="ff-medium underline ms-1">Term and Conditions</Link>
          </span>
          <GenericInputGroup inputGroup={agreeToTerms} />
        </div>
        <div className='w-100 d-flex f-column'>
          <span className='fs-13 text-darker-gray'>Please agree to the
            <Link to={"../terms-and-conditions/gdpr"} relative="path" className="ff-medium underline ms-1">Data Protection and Privacy terms</Link>
          </span>
          <GenericInputGroup inputGroup={agreeToGDPR} />
        </div>
      </div>


      <p className={`text-error fs-13 ${errorMsg && errorMsg.length > 0 ? "pb-2" : null}`}>
        {errorMsg}
      </p>

      <div className='w-100 d-flex justify-content-between pt-2'>
        <BackToLoginButton />
        <ModuleButton
          buttonType="submit"
          disabled={isLoading}
          isLoading={isLoading}
          labelKey="main.register"
        />
      </div>
    </>
  )
}

export default Register;

