import type { FormikHelpers } from "formik";
import { Formik } from "formik";
import { useEffect, useState, useMemo } from "react";
import GenericTierTwo from "../GenericTierTwo";
import GenericModule from "../GenericModule";
import GenericInputGroup from "../../../../components/input/inputGroup/GenericInputGroup";

import {
	initialValues,
	validationSchema,
	getSchemas,
	t2InitialValues,
} from "./otherLand";
import {
	useAppSelector,
	useSyncNotesWithStore,
	useThreadsProcessor,
} from "../../../../app/hooks";
import { BEModules } from "../../../../utils/moduleList";
import {
	useGetOtherLandDefaultsQuery,
	useGetOtherLandQuery,
	useUpdateOtherLandMutation,
} from "../../../../app/features/api/modules/otherLandApiSlice";
import { selectCurrentProject } from "../../../../app/features/project/projectSlice";
import type { ModuleFull } from "./otherLandTypes";
import useModuleHook from "../useModuleHook";
import useErrorMessage from "../../../../utils/useErrorMessage";
import useLandUseTypeInput from "../useLandUseTypeInput";

const OtherLand = () => {
	const { activeActivityId } = useAppSelector(selectCurrentProject);
	const {
		data: otherLandData,
		isLoading,
		isError,
		error,
	} = useGetOtherLandQuery(activeActivityId ?? 0, {
		refetchOnMountOrArgChange: true,
	});
	const [
		updateOtherLand,
		{ error: updateError, isError: isUpdateError, isLoading: isLoadingUpdate },
	] = useUpdateOtherLandMutation();

	const { data: defaults, error: defaultsError, isError: isDefaultsError, isLoading: isLoadingDefaults } = useGetOtherLandDefaultsQuery(
		otherLandData?.module.id ?? 0,
		{ skip: !otherLandData?.module.id },
	);
	const { errorMsg: defaultsErrorMsg } = useErrorMessage({ isError: isDefaultsError, error: defaultsError });


	const { moduleSchema, t2Schema } = useMemo(() => getSchemas(), []);
	const [initValues, setInitValues] = useState<ModuleFull>({
		module: initialValues,
		tiertwo: t2InitialValues,
	});

	const { tabsOpen, errorMsg } = useModuleHook({
		skeletonsLoadingDeps: [isLoading, isLoadingDefaults],
		isMutateError: isUpdateError,
		mutateError: updateError,
	});
	const { errorMsg: blockingError } = useErrorMessage({ isError, error });
	const {processModuleThreads} = useThreadsProcessor<ModuleFull>()

	useEffect(() => {
		if (otherLandData) {
			setInitValues({
				module: otherLandData.module,
				tiertwo: { ...otherLandData.tiertwo, ...defaults },
			});
			processModuleThreads({
				module: otherLandData.module,
			})
		}
	}, [otherLandData, defaults, processModuleThreads]);

	const { notes } = useSyncNotesWithStore({
		notes: otherLandData?.module.note ?? null,
	});
	const handleSubmit = async (
		values: ModuleFull,
		{ resetForm }: FormikHelpers<ModuleFull>,
	) => {
		const module = { ...values.module };
		const payload = {
			data: { ...values, module },
			activityId: activeActivityId ?? 0,
		};

		try {
			values.module.id && (await updateOtherLand(payload));
			resetForm({ values });
		} catch (error) {
			console.error(error);
		}
	};

	const input = useLandUseTypeInput({
		inputName: "module.land_use_type"
	});

	return (
		<Formik
			validationSchema={validationSchema}
			initialValues={initValues}
			onSubmit={handleSubmit}
			enableReinitialize
		>
			<>
				<GenericModule
					title="project.other_land"
					t2Schema={t2Schema}
					moduleSchema={moduleSchema}
					beModuleType={BEModules.OtherLand}
					isLoading={isLoadingUpdate}
					footerError={errorMsg}
					blockingError={blockingError}
					isError={isUpdateError}
				>
					{() => (
						<div className="py-2">
							<GenericInputGroup inputGroup={input} />
						</div>
					)}
				</GenericModule>

				{!tabsOpen ? (
					<GenericTierTwo
						title="project.other_land"
						t2Schema={t2Schema}
						tabsOpen={tabsOpen}
						warningMessage={defaultsErrorMsg ?? null}
					/>
				) : null}
			</>
		</Formik>
	);
};

export default OtherLand;
