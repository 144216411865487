import * as Yup from "yup";
import type { BEModules } from "../../../utils/moduleList";
import type { LandUseChangeResponse } from "./landUseChanges/landUseChangeTypes";
import { AnyObject } from "yup/lib/types";
import { BelongsToLuc, FetchOptionsKit, FieldType, InputGroup, InputType, LucDropdownInputGroup, Option, StartWithWithoutGeneric, TranslationKey } from "../../../types/modulesInterfaces";
import { isSWW } from "../../../utils/helperFunctions";
import { Activity, LandUseType, Project } from "../../../types/interfaces";
import { getOptions } from "../../../app/features/dropdownOptions/fetchOptions";

export const validatePercentage = Yup.number()
	.max(100, "validations.percentage_max_min")
	.min(0, "validations.percentage_max_min")
	.nullable();

export const validateStrictPercentage = validatePercentage.moreThan(0, "validations.percentage_strict");

export const validatePositiveNumber = Yup.number().nullable().min(0, "validations.number_positive")

export const createStandardLucValidation = (belongsToLuc?: BelongsToLuc) => {
	return (message: any, scenario: 'start' | 'w' | 'wo', module: BEModules) =>
		standardLucValidation(message, scenario, module, undefined, belongsToLuc);
};

export function getBelongsToLuc(scenario: 'start' | 'w' | 'wo', belongsToLuc?: BelongsToLuc | undefined) {
	switch (scenario) {
		case 'start':
			return belongsToLuc?.is_start
		case 'w':
			return belongsToLuc?.is_w
		case 'wo':
			return belongsToLuc?.is_wo
	}
}
export const standardLucValidation = (message: TranslationKey, scenario: 'start' | 'w' | 'wo', module: BEModules, baseValidation?: Yup.NumberSchema<number | null | undefined, AnyObject, number | null | undefined>, belongsToLuc?: BelongsToLuc | undefined) => {
	const validation = baseValidation ?? validatePositiveNumber
	const shouldValidate = !belongsToLuc || getBelongsToLuc(scenario, belongsToLuc);
	return shouldValidate ? validation.required(message) : validation
}
export const handleDisableSww = ({
	input,
	isReadOnly,
	belongsToLuc,
	index
}: {
	input: InputGroup | StartWithWithoutGeneric,
	isReadOnly: boolean,
	belongsToLuc?: BelongsToLuc,
	index?: number,
}
) => {
	if (!isSWW(input.type)) return input;
	const swwInput = input as StartWithWithoutGeneric;
	return {
		...swwInput,
		index: index ?? swwInput.index,
		startProps: {
			...swwInput.startProps,
			disabled:
				(belongsToLuc && !belongsToLuc.is_start) ||
				swwInput.startProps?.disabled ||
				isReadOnly,
		},
		withProps: {
			...swwInput.withProps,
			disabled:
				(belongsToLuc && !belongsToLuc.is_w) ||
				swwInput.withProps?.disabled ||
				isReadOnly,
		},
		withoutProps: {
			...swwInput.withoutProps,
			disabled:
				(belongsToLuc && !belongsToLuc.is_wo) ||
				swwInput.withoutProps?.disabled ||
				isReadOnly,
		},
	} as StartWithWithoutGeneric;
};

export function getSWWInputGroup({
	swwInput,
	isReadOnly,
	belongsToLuc,
	index
}: {
	swwInput: StartWithWithoutGeneric,
	isReadOnly: boolean,
	belongsToLuc?: BelongsToLuc,
	index?: number,
}
): StartWithWithoutGeneric {
	return {
		...swwInput,
		index: index ?? swwInput.index,
		startProps: {
			...swwInput.startProps,
			disabled:
				(belongsToLuc && !belongsToLuc.is_start) ||
				swwInput.startProps?.disabled ||
				isReadOnly,
		},
		withProps: {
			...swwInput.withProps,
			disabled:
				(belongsToLuc && !belongsToLuc.is_w) ||
				swwInput.withProps?.disabled ||
				isReadOnly,
		},
		withoutProps: {
			...swwInput.withoutProps,
			disabled:
				(belongsToLuc && !belongsToLuc.is_wo) ||
				swwInput.withoutProps?.disabled ||
				isReadOnly,
		},
	}
}

export const filterLucTypesByClimateMoisture = (
	activity: Activity | null | undefined,
	lucTypes: Option[] | undefined,
): Option[] => {
	if (!activity || !lucTypes) return lucTypes ?? [];
	return lucTypes.filter((option) => {
		const lucType = option as unknown as LandUseType
		const applicableToClimate = lucType.climates.some((climate) => climate === activity?.climate_t2?.id)
		const applicableToMoisture = lucType.moistures.some((moisture) => moisture === activity?.moisture_t2?.id)
		return applicableToClimate && applicableToMoisture
	})
}

type fetchLandUseTypesParams = {
	beModuleId?: BEModules, 
	activity?: Activity | null | undefined, 
	project?: Project | null | undefined 
}
export const fetchLandUseTypes = ({ beModuleId, activity, project }: fetchLandUseTypesParams): () => Promise<FetchOptionsKit> => {
	const climate  = activity?.climate_t2?.id || project?.climate.id
	const moisture = activity?.moisture_t2?.id || project?.moisture.id
	return () => getOptions("landUseType", { filterId: beModuleId , climate: climate ?? undefined, moisture: moisture ?? undefined });
}

interface TranslationUnitMapping {
	numberOfYears: TranslationKey;
}
const translationUnitTypes: TranslationUnitMapping = {
	numberOfYears: "module.number_of_years",
}

export enum UnitType {
	Number_of_Years = "Number of years",
}

export const UnitManager = {
	getUnit: (unitType: UnitType): string => {

		switch (unitType) {
			case UnitType.Number_of_Years:
				return translationUnitTypes.numberOfYears
			default:
				return "";
		}
	},
};

export const isLucDropdownInput = (input: InputGroup): input is LucDropdownInputGroup => {
	return input.type === FieldType.SELECT_LUC
}

export const addModuleTypeToOptionsKit = (input: InputGroup, module_type: number | undefined): InputGroup => {
	if (!module_type) return input
	if (isLucDropdownInput(input)) {
		const originalDropdownOptions = input.dropdownOptions;
		input.dropdownOptions = () => originalDropdownOptions(module_type);
		return input
	}
	return input
}
export function getThreadIds<T>(modules: Partial<T>) {
	const threadIds: number[] = [];
	for (const moduleKey in modules) {
		const moduleSection = modules[moduleKey];
		if (Array.isArray(moduleSection)) {
			for (const repeatable of moduleSection) {
				getThreadFieldFromSection(repeatable);
			}
		} else if (moduleSection) {
			getThreadFieldFromSection(moduleSection);
		}
	}
	return threadIds;

	function getThreadFieldFromSection(section: Record<string, unknown>) {
		for (const key in section) {
			const value = section[key];
			if (key.includes("thread") && value) {
				threadIds.push(value as number);
			}
		}
	}
}

export function formatDefaultT2(value: number | undefined | boolean | string, inputType: InputType | undefined) : string {
	if (!value) return ""
	let defaultValue = value.toString()
	if (inputType === InputType.PERCENTAGE && typeof value !== 'boolean') {
		const defaultNumber = +value
		defaultValue = `${(defaultNumber * 100).toString()}%`
	}
	return defaultValue
}