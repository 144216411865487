import { FirebaseApp, initializeApp } from "firebase/app";
import { Analytics, getAnalytics, logEvent, setUserProperties, setUserId } from "firebase/analytics";
import { User } from "../types/interfaces";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useAppSelector } from "../app/hooks";
import { selectCurrentUser } from "../app/features/auth/authSlice";
import { useLocation } from "react-router-dom";
import { FEModules, FEModulesObj } from "./moduleList";

const firebaseConfig = {
  apiKey: "AIzaSyD-BXKvctJrjJ8QT-AbpX5094vMqKGwqYY",
  authDomain: "fao-exact.firebaseapp.com",
  projectId: "fao-exact",
  storageBucket: "fao-exact.appspot.com",
  messagingSenderId: "913617318560",
  appId: "1:913617318560:web:ec9600a6d101af6d7a4fc5",
  measurementId: "G-GWJP2ERZJC"
};

export enum EventTypes {
  // auth events
  login = "login",
  sign_up = "sign_up",
  delete_user = "delete_user",
  // general events
  screen_view = "screen_view",
  app_crashed = "app_crashed",
  module_results_error = "module_results_error",
  // project events
  create_project = "create_project",
  duplicate_project = "duplicate_project",
  delete_project = "delete_project",
  edit_project = "edit_project",
  share_project = "share_project",
  // activity events
  create_activity = "create_activity",
  edit_activity = "edit_activity",
  duplicate_activity = "duplicate_activity",
  delete_activity = "delete_activity",
}

let firebaseApp: { app: FirebaseApp; analytics: Analytics } | null = null;

export const useFirebaseHook = () => {
  const initializeFirebase = () => {
    if (!firebaseApp) {
      const app = initializeApp(firebaseConfig);
      const analytics = getAnalytics(app); // we'll use this to log events: logEvent(analytics, 'event_name');
      firebaseApp = { app, analytics };
    }
  }

  const fireEvent = (eventType: EventTypes, eventData?: any) => {
    if (firebaseApp?.analytics) {
      logEvent(firebaseApp.analytics, eventType as string, eventData);
    }
  }

  return { initializeFirebase, fireEvent };
}


export const useSetFirebaseUserProperties = () => {
  const setUser = (user: User, analytics: Analytics) => {
    setUserId(analytics, `${user.id}`);
    setUserProperties(analytics, {
      organization: user.organization,
      country: user.country,
    });
  }

  const setLanguage = (language: string, analytics: Analytics) => {
    setUserProperties(analytics, {
      language: language,
    });
  }

  const user = useAppSelector(selectCurrentUser);
  const { i18n } = useTranslation();

  useEffect(() => {
    if (firebaseApp?.analytics && user)
      setUser(user, firebaseApp.analytics);
  }, [user, firebaseApp]);

  useEffect(() => {
    if (firebaseApp?.analytics && i18n.language)
      setLanguage(i18n.language, firebaseApp.analytics);
  }, [i18n.language, firebaseApp]);
}



enum ScreenNames {
  login = "login",
  register = "register",
  resetPassword = "reset_password",
  termsAndConditions = "terms_and_conditions",

  projectCreate = "project_create",
  projectEdit = "project_edit",
  projectResults = "project_results",
  selectProject = "select_project",

  // activityEditor = "activity_editor",
  activitiesList = "activities_list",
  activityCreate = "activity_create",
  activityEdit = "activity_edit",
  activityEditModules = "activity_edit_modules",
  activityComplete = "activity_complete",

  annualCropland = "annual_cropland",
  landUseChange = "land_use_change",
  largeFisheries = "large_fisheries",
  smallFisheries = "small_fisheries",
  grassland = "grassland",
  perennialCropland = "perennial_cropland",
  livestock = "livestock",
  floodedRice = "flooded_rice",
  aquaculture = "aquaculture",
  inputs = "inputs",
  forestManagement = "forest_management",
  waterbodies = "waterbodies",
  setAside = "set_aside",
  otherLand = "other_land",
  energy = "energy",
  settlements = "settlements",
  irrigation = "irrigation",
  coastalWetlands = "coastal_wetlands",
  organicSoil = "organic_soil",
}

export const useScreenViewEventsHandler = () => {
  const { fireEvent } = useFirebaseHook();
  const { pathname } = useLocation();

  const handleScreenView = (screenName: string) => {
    fireEvent(EventTypes.screen_view, { screen_name: screenName });
  }

  useEffect(() => {
    const substrings = pathname.split("/");
    const root = substrings[1];

    if (root === "auth") {
      if (substrings[2] === "login") {
        handleScreenView(ScreenNames.login);
      } else if (substrings[2] === "register") {
        handleScreenView(ScreenNames.register);
      } else if (substrings[2] === "reset-password") {
        handleScreenView(ScreenNames.resetPassword);
      } else if (substrings[2] === "terms-and-conditions") {
        handleScreenView(ScreenNames.termsAndConditions);
      }
    }
    if (root === "") handleScreenView(ScreenNames.selectProject)
    if (root === "project") {
      if (substrings[3] === "description") {
        substrings[2] === "new" ?
          handleScreenView(ScreenNames.projectCreate) : handleScreenView(ScreenNames.projectEdit)
      }
      if (substrings[3] === "activities") {
        handleScreenView(ScreenNames.activitiesList)
      }
      if (substrings[3] === "new-activity") {
        handleScreenView(ScreenNames.activityCreate)
      }
      if (substrings[3] === "results") {
        handleScreenView(ScreenNames.projectResults)
      }
    }

    if (root === "activity") {
      if (substrings[3] === "edit") {
        handleScreenView(ScreenNames.activityEdit)
      }
      if (substrings[3] === "edit-modules") {
        handleScreenView(ScreenNames.activityEditModules)
      }
    }

    if (root === "builder") {
      switch (substrings[3]) {
        case FEModulesObj[FEModules.AnnualCropland].path:
          handleScreenView(ScreenNames.annualCropland)
          break;
        case FEModulesObj[FEModules.LandUseChange].path:
          handleScreenView(ScreenNames.landUseChange)
          break;
        case FEModulesObj[FEModules.LargeFisheries].path:
          handleScreenView(ScreenNames.largeFisheries)
          break;
        case FEModulesObj[FEModules.SmallFisheries].path:
          handleScreenView(ScreenNames.smallFisheries)
          break;
        case FEModulesObj[FEModules.Grassland].path:
          handleScreenView(ScreenNames.grassland)
          break;
        case FEModulesObj[FEModules.PerennialCropland].path:
          handleScreenView(ScreenNames.perennialCropland)
          break;
        case FEModulesObj[FEModules.LivestockManagement].path:
          handleScreenView(ScreenNames.livestock)
          break;
        case FEModulesObj[FEModules.FloodedRice].path:
          handleScreenView(ScreenNames.floodedRice)
          break;
        case FEModulesObj[FEModules.Aquaculture].path:
          handleScreenView(ScreenNames.aquaculture)
          break;
        case FEModulesObj[FEModules.Inputs].path:
          handleScreenView(ScreenNames.inputs)
          break;
        case FEModulesObj[FEModules.ForestManagement].path:
          handleScreenView(ScreenNames.forestManagement)
          break;
        case FEModulesObj[FEModules.Waterbodies].path:
          handleScreenView(ScreenNames.waterbodies)
          break;
        case FEModulesObj[FEModules.SetAside].path:
          handleScreenView(ScreenNames.setAside)
          break;
        case FEModulesObj[FEModules.OtherLand].path:
          handleScreenView(ScreenNames.otherLand)
          break;
        case FEModulesObj[FEModules.Energy].path:
          handleScreenView(ScreenNames.energy)
          break;
        case FEModulesObj[FEModules.Settlements].path:
          handleScreenView(ScreenNames.settlements)
          break;
        case FEModulesObj[FEModules.Irrigation].path:
          handleScreenView(ScreenNames.irrigation)
          break;
        case FEModulesObj[FEModules.CoastalWetlands].path:
          handleScreenView(ScreenNames.coastalWetlands)
          break;
        case FEModulesObj[FEModules.OrganicSoil].path:
          handleScreenView(ScreenNames.organicSoil)
          break;
        case FEModulesObj[FEModules.ActivityComplete].path:
          handleScreenView(ScreenNames.activityComplete)
          break;
        default:
          break;
      }
    }
  }, [pathname]);
}