import { useEffect, useRef, useState } from "react";
import { getIn } from "formik";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import type { SelectInstance, SingleValue } from "react-select";
import type { FC } from "react";
import type { DropdownOptions, Option, TranslationKey } from "../../types/modulesInterfaces";
import type { FieldProps } from "formik";
import { useCustomTranslationHandler, useGetAppLanguage } from "../../app/hooks";

interface CustomSelectProps {
  label: TranslationKey;
  type: "text" | "password" | "select";
  options: DropdownOptions;
  classes?: string;
  disabled?: boolean;
  autosubmit?: boolean;
  onChange?: (e: any) => void;
}

interface ReadOnlyOption {
  readonly label: string;
  readonly value: number;
}

const selectOption = (option: Option): ReadOnlyOption => ({
  label: option.name,
  value: option.id,
});

const CustomSelectInput: FC<CustomSelectProps & FieldProps> = ({
  label,
  field,
  form,
  type,
  options,
  classes,
  disabled,
  form: { touched, errors, setTouched },
  autosubmit,
  onChange,
  ...props
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [ddOptions, setDdOptions] = useState<ReadOnlyOption[]>([]);
  const error = getIn(errors, field.name);
  const wasTouched = getIn(touched, field.name);
  const selectRef = useRef<SelectInstance<ReadOnlyOption> | null>(null);
  const { apiLanguageField } = useGetAppLanguage()

  useEffect(() => {
    const getOptions = async () => {
      setIsLoading(true);
      const rawOptions: Option[] = typeof options === "function"
        ? (await options()).data
        : options || [];

      const translatedOptions = rawOptions.map((option) => ({
        ...option,
        name: option[apiLanguageField] ?? option.name,
      }));
      setDdOptions(translatedOptions.map(selectOption));
      setIsLoading(false);
    };

    getOptions();
  }, [options, apiLanguageField]);

  const handleSelect = (newValue: SingleValue<ReadOnlyOption>) => {
    if (newValue?.value !== undefined && newValue?.value !== null) {
      const event = {
        persist: () => { },
        target: {
          type: "change",
          id: field.name,
          name: field.name,
          value: newValue?.value,
        },
      };
      field.onChange(event);
      onChange?.(event);
    }
  };

  const animatedComponents = makeAnimated();

  const translationHandler = useCustomTranslationHandler();
  const labelEl = (hasValue: boolean) => ({
    ":before": {
      content: `"${translationHandler(label)}"`,
      transform: `translateY(${hasValue ? "-5px" : "3px"})`,
      display: "block",
      fontSize: hasValue ? 10 : 12,
      color: "var(--dark-gray)",
      position: "absolute" as const,
      top: 8,
      left: 10,
      zIndex: 100,
      transition: "transform 0.2s ease",
    },
  });

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      selectRef.current?.openMenu('first');
    }
  };

  return (
    <Select
      ref={selectRef}
      isMulti={false}
      components={animatedComponents}
      isDisabled={isLoading || disabled}
      isLoading={isLoading}
      onChange={handleSelect}
      onFocus={() => setTouched({ [field.name]: true })}
      options={ddOptions}
      value={field.value ? ddOptions?.find((option) => option.value === field.value) : null}
      menuPortalTarget={document.body}
      styles={{
        indicatorSeparator: () => ({
          display: "none",
        }),
        container: (baseStyles) => ({
          ...baseStyles,
          width: "100%",
          height: 36,
          fontFamily: "AIAIAIv4 normal",
        }),
        singleValue: (baseStyles, state) => ({
          ...baseStyles,
          fontWeight: 500,
          fontSize: 13,
        }),
        input: (baseStyles) => ({
          ...baseStyles,
        }),
        placeholder: (baseStyles) => ({
          ...baseStyles,
          display: "none"
        }),
        control: (baseStyles, state) => ({
          ...baseStyles,
          border: `1px solid ${state.isDisabled
            ? "#999999"
            : state.isFocused
              ? "var(--dark-gray)"
              : error && wasTouched
                ? "var(--error)"
                : "var(--gray)"
            } !important`,
          borderRadius: 6,
          backgroundColor: state.isDisabled
            ? "#eeeeee" : "var(--white)",
          boxShadow: "none",
          ...labelEl(state.hasValue || state.menuIsOpen),
        }),
        valueContainer: (baseStyles, state) => ({
          ...baseStyles,
          color: "var(--gray)",
          fontSize: 13,
          fontFamily: state.hasValue ? "AIAIAIv4 medium" : "AIAIAIv4 normal",
          transform: `translateY(${state.hasValue ? "5px" : 0})`,
        }),
        menuPortal: (baseStyle) => ({ ...baseStyle, zIndex: 9999 }),
        menu: (baseStyles) => ({
          ...baseStyles,
          background: "white",
          minWidth: 100,
          borderRadius: 8,
          outline: 0,
          border: "1px solid var(--dark-gray)",
          fontSize: 13,
        }),
        option: (baseStyles, state) => ({
          ...baseStyles,
          background:
            state.isFocused || state.isSelected ? "#eee" : "transparent",
          color:
            state.isSelected || state.isFocused ? "black" : baseStyles.color,
        }),
      }}
      {...props}
    />
  );
};

export default CustomSelectInput;