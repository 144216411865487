import { t } from "i18next";
import { formatNumber } from "../../utils/helperFunctions";


const CustomTooltip = ({ active, payload, label, customLabel }: any) => {
	if (active && payload && payload.length) {
		return (
			<div className="custom-tooltip" style={{ backgroundColor: '#fff', border: '1px solid #ccc', padding: '10px' }}>
				<p className="label">{customLabel ? customLabel(label): label}</p>
				<ul style={{ listStyleType: "none", paddingLeft: 0 }}>
					{payload.map((entry: any, index: number) => {
						const { name, value, color } = entry;
						let formattedNumber = formatNumber(value,2);
						const formattedName = {
							CO2: "CO<sub>2</sub>",
							CH4: "CH<sub>4</sub>",
							N2O: "N<sub>2</sub>O",
						}[name as 'CO2' | 'CH4' | 'N2O'] || name;

						return (
							<li key={`tooltip-item-${index}`} style={{ color }}>
								<span
									dangerouslySetInnerHTML={{ __html: `${formattedName}: ${formattedNumber ?? value}` }}
								/>
							</li>
						);
					})}
				</ul>
			</div>
		);
	}

	return null;
};

export default CustomTooltip;