import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { FieldType, TranslationKey } from "../types/modulesInterfaces";
import i18n from "../i18n";

export const capitalize = (str: string | null) => {
	return str ? str.charAt(0).toUpperCase() + str.slice(1) : undefined;
};

export const capitalizeEach = (str: string) =>
	str
		.split(" ")
		.map((word) => capitalize(word))
		.join(" ");

export function isFetchBaseQueryError(
	error: unknown,
): error is FetchBaseQueryError {
	return (
		typeof error === "object" &&
		error != null &&
		"status" in error &&
		"data" in error
	);
}

export const isSWW = (inputType: FieldType) =>
	[FieldType.SWW, FieldType.SWW_BOOL, FieldType.SWW_SELECT, FieldType.SWW_T2].includes(inputType);


export const translate = (key: TranslationKey) => {
	return i18n.t(key);
}

export const  formatNumber = (num: number | string, decimalPlaces = 2): string => {
	let fixed
	if (typeof num === "string") {
		fixed = parseInt(parseInt(num).toFixed(decimalPlaces))
	} else {		
		fixed = parseInt(num.toFixed(decimalPlaces));
	}
	const options = {  maximumFractionDigits: 2   }  
	const formattedNumber = Intl.NumberFormat("en-US",options).format(fixed); 
	return formattedNumber
}