import type { FormikHelpers } from "formik";
import { Formik } from "formik";
import { useEffect, useMemo, useState } from "react";
import useModuleHook from "../useModuleHook";
import GenericTierTwo from "../GenericTierTwo";
import GenericModule from "../GenericModule";

import type { ModuleFull } from "./largeFisheriesTypes";
import {
	getSchemas,
	validationSchema,
	initialValues,
	t2InitialValues,
} from "./largeFisheries";
import {
	useAppSelector,
	useThreadsProcessor,
} from "../../../../app/hooks";
import { selectCurrentProject } from "../../../../app/features/project/projectSlice";
import {
	useGetLargeFisheriesDefaultsQuery,
	useGetLargeFisheriesQuery,
	useUpdateLargeFisheriesMutation,
} from "../../../../app/features/api/modules/largeFisheryApiSlice";
import { BEModules } from "../../../../utils/moduleList";
import useErrorMessage from "../../../../utils/useErrorMessage";

const LargeFisheriesModule = () => {
	const { activeActivityId } = useAppSelector(selectCurrentProject);
	const {
		data: largeFishData,
		isLoading,
		isError,
		error,
	} = useGetLargeFisheriesQuery(activeActivityId ?? 0, {
		refetchOnMountOrArgChange: true,
	});
	const { data: defaults, isError: isDefaultsError, error: defaultsError, isLoading: isLoadingDefaults } = useGetLargeFisheriesDefaultsQuery(
		largeFishData?.module.id ?? 0,
		{ skip: !largeFishData?.module.id },
	);
	const { errorMsg: defaultsErrorMsg } = useErrorMessage({ isError: isDefaultsError, error: defaultsError });

	const [
		updateLargeFishery,
		{ error: updateError, isError: isUpdateError, isLoading: isLoadingUpdate },
	] = useUpdateLargeFisheriesMutation();

	const { moduleSchema, t2Schema } = useMemo(() => getSchemas(), []);
	const [initValues, setInitValues] = useState<ModuleFull>({
		module: initialValues,
		tiertwo: t2InitialValues,
	});

	const { tabsOpen, errorMsg } = useModuleHook({
		skeletonsLoadingDeps: [isLoading, isLoadingDefaults],
		isMutateError: isUpdateError,
		mutateError: updateError,
	});
	const { errorMsg: blockingError } = useErrorMessage({ isError, error });
	const {processModuleThreads} = useThreadsProcessor<ModuleFull>();

	useEffect(() => {
		if (largeFishData) {
			setInitValues({
				module: { ...largeFishData.module, ...defaults },
				tiertwo: { ...largeFishData.tiertwo, ...defaults },
			});
			processModuleThreads({
				module: largeFishData.module,
			})
		}
	}, [largeFishData, defaults, processModuleThreads]);

	const handleSubmit = async (
		values: ModuleFull,
		{ resetForm }: FormikHelpers<ModuleFull>,
	) => {
		const payload = {
			data: values,
			activityId: activeActivityId ?? 0,
		};

		try {
			values.module.id && (await updateLargeFishery(payload).unwrap());
			resetForm({ values });
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<Formik
			validationSchema={validationSchema}
			initialValues={initValues}
			onSubmit={handleSubmit}
			enableReinitialize
		>
			<>
				<GenericModule
					title="project.large_fisheries"
					moduleSchema={moduleSchema}
					t2Schema={t2Schema}
					beModuleType={BEModules.LargeFisheries}
					isLoading={isLoadingUpdate}
					footerError={errorMsg}
					blockingError={blockingError}
					isError={isUpdateError}
				/>
				{!tabsOpen ? (
					<GenericTierTwo
						title="project.large_fisheries"
						t2Schema={t2Schema}
						tabsOpen={tabsOpen}
						warningMessage={defaultsErrorMsg}
					/>
				) : null}
			</>
		</Formik>
	);
};

export default LargeFisheriesModule;
