import type { ClassicInputGroup } from "../../../../types/modulesInterfaces";
import { checkboxesOrder, finishValidationSchema, isAreaRequiredModules, landUseOptions } from "./setupAssistantUtils";
import { Field, Form, Formik, getIn, useFormikContext } from "formik";
import type { FormikValues } from "formik";
import type {
	CheckboxesInputGroup,
	StartWithWithoutGeneric,
} from "../../../../types/modulesInterfaces";
import { FieldType } from "../../../../types/modulesInterfaces";
import ModuleButton from "../../../../components/button/ModuleButton";
import { motion } from "framer-motion";
import { useNavigate, useParams } from "react-router-dom";
import type {
	ContextModules,
	SetupAssistantForm,
} from "./setupAssistant.types";
import {
	BEModules,
	BEModulesObj,
	FEModules,
	FEModulesObj,
	isInFEModules,
} from "../../../../utils/moduleList";
import { useCallback, useEffect, useMemo, useState, useRef } from "react";
import type { FC } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { selectIsFetchingData } from "../../../../app/features/builder/builderSlice";
import { useCreateActivityMutation, useGetActivitiesByIdQuery } from "../../../../app/features/activities/activityApiSlice";
import type { ActivityCreatePayload } from "../../../../app/features/activities/activityTypes";
import { selectCurrentProject } from "../../../../app/features/project/projectSlice";
import { clearProgressiveModules } from "../../../../app/features/setupAssistant/setupAssistantSlice";
import GenericInputGroup from "../../../../components/input/inputGroup/GenericInputGroup";
import FormRow from "../../../../components/input/formRow/FormRow";
import Skeleton from "react-loading-skeleton";
import useErrorMessage from "../../../../utils/useErrorMessage";
import CreateActivityError from "./CreateActivityError";
import Button from "../../../../components/button/Button";
import { MdArrowBack } from "react-icons/md";
import TranslatableText from "../../../../components/translations/TranslatableText";
import { useGetLandUseChangesQuery } from "../../../../app/features/api/modules/landUseChangeApiSlice";
import ConfirmationModal from "../../../../components/modal/ConfirmationModal";
import FooterError from "../../../activityBuilder/modules/FooterError";
import { translate } from "../../../../utils/helperFunctions";
import { BiSave } from "react-icons/bi";
import { EventTypes, useFirebaseHook } from "../../../../utils/firebaseUtils";

export interface FinalForm {
	modulesList: string[];
	landUse_start: number | undefined;
	landUse_w: number | undefined;
	landUse_wo: number | undefined;
	area: number | undefined;
}
const initValues: FinalForm = {
	modulesList: [],
	area: undefined,
	landUse_start: undefined,
	landUse_w: undefined,
	landUse_wo: undefined,
};

const SetupAssistantFinish = ({
	modules,
	values,
	onReturn,
}: {
	modules: ContextModules[];
	onReturn: () => void;
	values: SetupAssistantForm;
}) => {
	const params = useParams<string>();
	const { activityId } = params;
	const id = useMemo(() => (activityId ? +activityId : null), [activityId]);
	const isEdit = useMemo<boolean>(() => !!id, [id]);
	const { data: activityData, isLoading: activityIsLoading } = useGetActivitiesByIdQuery(id ?? 0, { skip: !id });
	const { data: lucData, isLoading: lucIsLoading } = useGetLandUseChangesQuery(id ?? 0, {
		refetchOnMountOrArgChange: true,
		skip: !isEdit
	});

	const {
		activityTitle,
		climate,
		typeOfSoil,
		hectares,
		previousLandUse,
		mainActivityFocus,
		duration,
		withoutProjectLandUse,
		moisture,
		activityCost,
		changeRate,
		startYear,
	} = useMemo(() => values, [values]);

	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const { project } = useAppSelector(selectCurrentProject);
	const { fireEvent } = useFirebaseHook();
	const [buildActivity, { isLoading, isError, isSuccess: isBuildSuccess, error }] =
		useCreateActivityMutation();

	const { errorMsg } = useErrorMessage({ error, isError });
	// const [errorDialog, setErrorDialog] = useState(false);

	const preSelected = useMemo(
		() => isEdit ? activityData?.modules.map((mod) => `${BEModulesObj[mod.module_type.id]}`) ?? [] : (modules.map((mod) => `${mod.module}`) ?? []),
		[modules, isEdit, activityData],
	);

	const area = useMemo(() => {
		if (!isEdit) return hectares;
		if (lucData?.module.area) return lucData?.module.area;
		return activityData?.modules.find(module => module?.area)?.area;
	}, [isEdit, lucData, activityData, hectares]);

	const lucs: {
		landUse_start: number | undefined;
		landUse_w: number | undefined;
		landUse_wo: number | undefined;
	} = useMemo(() => isEdit ? {
		landUse_start: lucData?.module.module_type_start ? BEModulesObj[lucData?.module.module_type_start] : undefined,
		landUse_w: lucData?.module.module_type_w ? BEModulesObj[lucData?.module.module_type_w] : undefined,
		landUse_wo: lucData?.module.module_type_wo ? BEModulesObj[lucData?.module.module_type_wo] : undefined,
	} : {
		landUse_start: previousLandUse,
		landUse_w: mainActivityFocus,
		landUse_wo: withoutProjectLandUse
			? withoutProjectLandUse
			: previousLandUse,
	}, [isEdit, values, lucData])

	const initialValues = useMemo(() =>
		isEdit ? {
			...initValues,
			modulesList: [...preSelected],
			area: area,
			...lucs
		} :
			{
				...initValues,
				modulesList: [...preSelected],
				area: hectares,
				...lucs
			}
		, [initValues, preSelected, area, lucs, isEdit, values])

	const modulesInput: CheckboxesInputGroup = useMemo(() => {
		const orderMap = new Map();
		checkboxesOrder.forEach((id, index) => orderMap.set(id, index));

		const options = Object.keys(FEModulesObj)
			.map((key) => ({
				name: FEModulesObj[Number(key) as FEModules].name,
				id: key,
			}))
			.filter((opt) => opt.id !== `${FEModules.ActivityComplete}`)
			.sort((a, b) => orderMap.get(+a.id) - orderMap.get(+b.id));

		return {
			inputName: "modulesList",
			label: "",
			options: options,
			type: FieldType.CHECKBOXES,
		};
	}, []);

	const [showConfirmModal, setShowConfirmModal] = useState(false);
	const [hasConfirmed, setHasConfirmed] = useState(false);
	const pendingSubmitRef = useRef<(() => Promise<void>) | null>(null);

	const handleCancel = () => {
		setShowConfirmModal(false);
		setHasConfirmed(false);
		pendingSubmitRef.current = null;
	};

	const handleConfirm = (submitForm: () => Promise<void>) => {
		setShowConfirmModal(false);
		setHasConfirmed(true);
		pendingSubmitRef.current = submitForm;
	};

	useEffect(() => {
		if (hasConfirmed && pendingSubmitRef.current) {
			const submitForm = pendingSubmitRef.current;
			pendingSubmitRef.current = null;
			submitForm();
		}
	}, [hasConfirmed]);

	const handleSubmit = useCallback(async (values: FormikValues) => {
		if (!hasConfirmed && isEdit) {
			setShowConfirmModal(true);
			return;
		}

		const localValues = { ...values } as FinalForm;
		if (!localValues.modulesList.includes(`${FEModules.LandUseChange}`)) {
			localValues.landUse_start = undefined;
			localValues.landUse_w = undefined;
			localValues.landUse_wo = undefined;
		}
		const beModuleList = localValues.modulesList.reduce<number[]>(
			(accum, current) => {
				const beModule = isInFEModules(Number(current))
					? FEModulesObj[Number(current) as FEModules]?.beModuleId
					: undefined;
				return beModule && beModule !== BEModules.LandUseChange
					? // biome-ignore lint/performance/noAccumulatingSpread: <explanation>
					[...accum, beModule]
					: accum;
			},
			[],
		);

		const { startModules, withModules, withoutModules } = getLUCBeModules({
			landUse_start: localValues.landUse_start,
			landUse_w: localValues.landUse_w,
			landUse_wo: localValues.landUse_wo,
		});

		const landUseChange = !localValues.modulesList.includes(
			`${FEModules.LandUseChange}`,
		)
			? null
			: {
				module_type_start: startModules.length
					? FEModulesObj[startModules[0] as FEModules].beModuleId
					: undefined,
				module_type_w: withModules.length
					? FEModulesObj[withModules[0] as FEModules].beModuleId
					: undefined,
				module_type_wo: withoutModules.length
					? FEModulesObj[withoutModules[0] as FEModules].beModuleId
					: undefined,
			};

		let payload: ActivityCreatePayload = {
			/* we have to filter out luc modules in the modules array */
			module_types: beModuleList.filter(
				(mod) =>
					![
						landUseChange?.module_type_start,
						landUseChange?.module_type_w,
						landUseChange?.module_type_wo,
					].includes(mod),
			),
			land_use_change: landUseChange,
			project: project?.id ?? 0,
			name: activityTitle ?? "",
			change_rate: changeRate ?? null,
			cost: activityCost ?? 0,
			climate_t2: climate === project?.climate?.id ? null : climate,
			moisture_t2: moisture === project?.moisture?.id ? null : moisture,
			soil_type_t2: typeOfSoil === project?.soil_type?.id ? null : typeOfSoil,
			start_year_t2: startYear === project?.start_year_of_activities || !startYear ? null : startYear,
			duration_t2: duration === project?.implementation_years || !duration ? null : duration,
		};
		if (localValues.area) payload = { ...payload, area: localValues.area };
		if (isEdit) payload = { ...payload, activity_id: id }

		buildActivity(payload)
			.unwrap()
			.then(() => fireEvent(EventTypes.create_activity))
			.catch((err) => {
				console.error(err);
				// setErrorDialog(true);
				setHasConfirmed(false);
			});
	}, [initialValues, activityTitle, climate, moisture, typeOfSoil, changeRate, startYear, duration, activityCost, id, isEdit, hasConfirmed, project]);

	useEffect(() => {
		if (isBuildSuccess) {
			dispatch(clearProgressiveModules());
			navigate(isEdit ? "../edit" : "../activities");
			setHasConfirmed(false);
		}
	}, [isBuildSuccess, navigate]);

	return (
		<section>
			<Formik
				initialValues={initialValues}
				enableReinitialize
				onSubmit={handleSubmit}
				validationSchema={finishValidationSchema}
			>
				{({ isValid, submitForm, errors, touched }) =>
				(<Form>
					<SpecialCheckBoxesInputGroup {...modulesInput} disabled={modulesInput.disabled || activityIsLoading || lucIsLoading} />
					<AreaInput />
					<div
						style={{
							display: "flex",
							alignItems: "center",
							gap: "10px",
							marginBottom: "1rem",
							paddingTop: "1rem"
						}}
					>
						<Button classes="btn-login" onClick={onReturn}>
							<TranslatableText className="bg-quat-de px-1 label ff-medium" translationKey={isEdit ? "main.back" : "activity.back_to_wizard"} />
							<div className="bg-quat-br icon">
								<MdArrowBack size={20} color="var(--white)" />
							</div>
						</Button>
						<ModuleButton icon={BiSave} disabled={isLoading || !isValid} isLoading={isLoading} labelKey={isEdit ? "main.save_changes" : "main.finalize_setup_and_save"} />
						<CreateActivityError isError={isError} message={isEdit ? "An error occured when saving changes" : undefined} />
					</div>
					<FooterError footerError={errorMsg} errors={errors} touched={touched} />
					<ConfirmationModal
						open={showConfirmModal}
						onCancel={handleCancel}
						onConfirm={() => handleConfirm(submitForm)}
						message="Are you sure you want to edit this activity? Some information may be lost."
						headerText="main.attention"
					/>
				</Form>)
				}
			</Formik>
		</section>
	);
};

export const SpecialCheckBoxesInputGroup: FC<CheckboxesInputGroup> = ({
	inputName,
	index,
	comment,
	help,
	options,
	label,
	disabled,
}) => {
	const [name, setName] = useState<string>("");
	const isFetchingData: boolean = useAppSelector(selectIsFetchingData);
	const { errors, touched, values } = useFormikContext<FinalForm>();
	const listIncludesLuc = useMemo(
		() => values.modulesList.includes(`${FEModules.LandUseChange}`),
		[values.modulesList],
	);

	useEffect(() => {
		setName(
			typeof inputName === "function" ? inputName(index ?? 0) : inputName,
		);

		return () => {
			setName("");
		};
	}, [inputName, index]);

	return (
		<FormRow
			style={{ height: "fit-content" }}
			rowComment={comment}
			rowHelp={help}
			error={
				getIn(errors, name) && getIn(touched, name)
					? getIn(errors, name)
					: undefined
			}
		>
			<div
				role="group"
				aria-labelledby={label}
				className="d-flex f-column w-100"
			>
				{isFetchingData ? (
					<Skeleton height="100%" count={3} />
				) : (
					options.map((option) => (
						<div className="d-flex f-column" key={option.id}>
							<label className="clickable d-flex align-items-center pb-1 w-fit">
								<Field
									type="checkbox"
									name={name}
									id={name}
									value={option.id}
									disabled={disabled}
								/>
								<div className="d-flex align-items-center">
									<TranslatableText className="ps-1 fs-12 ff-medium" translationKey={option.name} />
									<span>{listIncludesLuc ? "*" : ""}</span>
								</div>
							</label>
							{option.id === `${FEModules.LandUseChange}` && listIncludesLuc ? (
								<LucAdditionalInputs />
							) : null}
						</div>
					))
				)}
			</div>
		</FormRow>
	);
};

const getLUCBeModules = ({
	landUse_start,
	landUse_w,
	landUse_wo,
}: {
	landUse_start?: number;
	landUse_w?: number;
	landUse_wo?: number;
}) => {
	const startModules =
		landUseOptions.find((landOption) => landOption.id === landUse_start)
			?.derivedModules ?? [];
	const withModules =
		landUseOptions.find((landOption) => landOption.id === landUse_w)
			?.derivedModules ?? [];
	const withoutModules =
		landUseOptions.find((landOption) => landOption.id === landUse_wo)
			?.derivedModules ?? [];
	return {
		startModules,
		withModules,
		withoutModules,
	};
};

export const LucAdditionalInputs = () => {
	const {
		values: { landUse_start, landUse_w, landUse_wo, modulesList },
		setFieldValue,
	} = useFormikContext<FinalForm>();
	const [lucHistory, setLucHistory] = useState<string[]>([]);

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		const { startModules, withModules, withoutModules } = getLUCBeModules({
			landUse_start,
			landUse_w,
			landUse_wo,
		});
		const allModules = [...startModules, ...withModules, ...withoutModules];
		for (const module of allModules) {
			if (!lucHistory.includes(module.toString()))
				setLucHistory((prev) => [...prev, module.toString()]);
			if (!modulesList.includes(`${module}`))
				setFieldValue("modulesList", [...modulesList, `${module}`]);
		}
		const moduleListWithoutLuc = modulesList.filter(
			(module) => module !== FEModules.LandUseChange.toString(),
		);
		if (moduleListWithoutLuc.length > 0 && allModules.length > 0) {
			const moduleListWithoutSelectedLuc = moduleListWithoutLuc.filter(
				(module) => {
					return !allModules.some((lucModuleId) => {
						return lucModuleId.toString() === module.toString();
					});
				},
			);
			const moduleToUnCheck = lucHistory.find((lucModuleHistory) =>
				moduleListWithoutSelectedLuc.some((mod) => mod === lucModuleHistory),
			);
			const newModuleList = modulesList.filter(
				(mod) => mod !== moduleToUnCheck,
			);
			if (moduleListWithoutSelectedLuc.length > 0 && moduleToUnCheck) {
				const newHistory = lucHistory.filter(
					(history) => history !== moduleToUnCheck,
				);
				setLucHistory(newHistory);
				setFieldValue("modulesList", [...newModuleList]);
			}
		}
	}, [landUse_start, landUse_w, landUse_wo, modulesList, setFieldValue]);

	const getTranslatedLandUse = useCallback((): StartWithWithoutGeneric => {
		const translatedOptions = landUseOptions.map((option) => {
			return {
				...option,
				name: translate(option.name),
			};
		})
		return {
			type: FieldType.SWW_SELECT,
			inputName: "landUse",
			label: "module.type_of_land_use",
			startProps: { dropdownOptions: translatedOptions },
			withProps: { dropdownOptions: translatedOptions },
			withoutProps: { dropdownOptions: translatedOptions },
		};
	}, []);

	return (
		<motion.div
			animate={{ opacity: 1, height: "fit-content" }}
			initial={{ opacity: 0, height: 0 }}
			exit={{ opacity: 0, height: 0 }}
		>
			<GenericInputGroup inputGroup={getTranslatedLandUse()} showCommentIcon={false} />
		</motion.div>
	);
};

export const AreaInput = () => {
	const { values } = useFormikContext<FinalForm>();
	const showArea = useMemo<boolean>(
		() =>
			values.modulesList.some((mod) => isAreaRequiredModules.includes(+mod)),
		[values.modulesList],
	);

	const area: ClassicInputGroup = {
		inputName: "area",
		label: "main.area",
		type: FieldType.CLASSIC,
		inputType: "number",
		unit: "[ha]",
	};

	return (
		<>
			{showArea && (
				<motion.div
					transition={{ duration: 0.25 }}
					className="w-50 py-2"
					animate={{ opacity: 1, height: 70 }}
					initial={{ opacity: 0, height: 0 }}
					exit={{ opacity: 0, height: 0 }}
				>
					<GenericInputGroup inputGroup={area} />
				</motion.div>
			)}
		</>
	);
};

export default SetupAssistantFinish;
