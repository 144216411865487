import type { FormikHelpers } from "formik";
import { Formik } from "formik";
import { useEffect, useMemo, useState } from "react";
import GenericTierTwo from "../GenericTierTwo";
import GenericModule from "../GenericModule";

import {
	initialValues,
	validationSchema,
	getSchemas,
	t2InitialValues,
} from "./aquaculture";
import { useAppSelector, useSyncNotesWithStore } from "../../../../app/hooks";
import { BEModules } from "../../../../utils/moduleList";
import {
	useGetAquacultureDefaultsQuery,
	useGetAquaculturesQuery,
	useUpdateAquaculturesMutation,
} from "../../../../app/features/api/modules/aquacultureApiSlice";
import { selectCurrentProject } from "../../../../app/features/project/projectSlice";
import type { ModuleFull } from "./aquacultureTypes";
import useModuleHook from "../useModuleHook";
import useErrorMessage from "../../../../utils/useErrorMessage";

const Aquaculture = () => {
	// const newNotes = useAppSelector(selectNewCurrentModuleNotes);
	const { activeActivityId } = useAppSelector(selectCurrentProject);
	const {
		data: aquacultureData,
		isLoading,
		error,
		isError,
	} = useGetAquaculturesQuery(activeActivityId ?? 0, {
		refetchOnMountOrArgChange: true,
	});
	const [
		updateAquaculture,
		{ error: updateError, isError: isUpdateError, isLoading: isLoadingUpdate },
	] = useUpdateAquaculturesMutation();
	const { data: defaults, error: defaultsError, isError: isDefaultsError, isLoading: isLoadingDefaults } = useGetAquacultureDefaultsQuery(
		aquacultureData?.module.id ?? 0,
		{ skip: !aquacultureData?.module.id },
	);
	const { errorMsg: defaultsErrorMsg } = useErrorMessage({ isError: isDefaultsError, error: defaultsError });


	const { moduleSchema, t2Schema } = useMemo(() => getSchemas(), []);
	const [initValues, setInitValues] = useState<ModuleFull>({
		module: initialValues,
		tiertwo: t2InitialValues,
	});
	const { tabsOpen, errorMsg } = useModuleHook({
		skeletonsLoadingDeps: [isLoading, isLoadingDefaults],
		isMutateError: isUpdateError,
		mutateError: updateError,
	});
	const { errorMsg: blockingError } = useErrorMessage({ isError, error });

	useEffect(() => {
		if (aquacultureData)
			setInitValues({
				module: aquacultureData.module,
				tiertwo: { ...aquacultureData.tiertwo, ...defaults },
			});
	}, [aquacultureData, defaults]);

	const { notes } = useSyncNotesWithStore({
		notes: aquacultureData?.module.note ?? null,
	});
	const handleSubmit = async (
		values: ModuleFull,
		{ resetForm }: FormikHelpers<ModuleFull>,
	) => {
		const module = { ...values.module };
		const payload = {
			data: { ...values, module },
			activityId: activeActivityId ?? 0,
		};

		try {
			values.module.id && (await updateAquaculture(payload).unwrap());
			resetForm({ values });
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<Formik
			validationSchema={validationSchema}
			initialValues={initValues}
			onSubmit={handleSubmit}
			enableReinitialize
		>
			<>
				<GenericModule
					title="project.aquaculture"
					t2Schema={t2Schema}
					moduleSchema={moduleSchema}
					beModuleType={BEModules.Aquaculture}
					isLoading={isLoadingUpdate}
					footerError={errorMsg}
					blockingError={blockingError}
					isError={isUpdateError}
					note={notes?.content ?? null}
				/>
				{!tabsOpen ? (
					<GenericTierTwo
						title="project.aquaculture"
						t2Schema={t2Schema}
						tabsOpen={tabsOpen}
						warningMessage={defaultsErrorMsg || null}
					/>
				) : null}
			</>
		</Formik>
	);
};

export default Aquaculture;
