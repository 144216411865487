import { Component, ErrorInfo, ReactNode } from 'react';
import NoMatch404 from '../pages/accessManagement/NoMatch404';
import { EventTypes, useFirebaseHook } from '../utils/firebaseUtils';
interface ErrorBoundaryProps {
  children: ReactNode; // Define children prop
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  state: ErrorBoundaryState = { hasError: false };
  firebase = useFirebaseHook();

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // TODO: test if events is sent correctly
    console.error("ErrorBoundary caught an error:", error, errorInfo);
    this.firebase.fireEvent(EventTypes.app_crashed, { error, errorInfo });
  }

  render() {
    if (this.state.hasError) {
      return <NoMatch404 translationKey1="main.error_captured" translationKey2="main.error_occurred" />;
    }

    return this.props.children; // Render children if no error
  }
}

export default ErrorBoundary;
