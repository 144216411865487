import type { StartWithWithoutGeneric } from "../../../types/modulesInterfaces";
import { FieldType } from "../../../types/modulesInterfaces";
import { useGetLandUseTypesQuery } from "../../../app/features/dropdownOptions/dropdownOptionsApiSlice";
import { useMemo } from "react";
import { useAppSelector, useFetchLucTypes } from "../../../app/hooks";
import { selectCurrentProject } from "../../../app/features/project/projectSlice";
import { fetchLandUseTypes } from "./moduleUtils";

const useLandUseTypeInput = ({ inputName }: { inputName: string }) => {

  const { landUseTypes } = useFetchLucTypes({})
  const landUseChangeInput: StartWithWithoutGeneric = useMemo(
    () => ({
      type: FieldType.SWW_SELECT,
      inputName: inputName,
      label: "module.type_of_land_use",
      startProps: { dropdownOptions: landUseTypes, disabled: true },
      withProps: { dropdownOptions: landUseTypes, disabled: true },
      withoutProps: { dropdownOptions: landUseTypes, disabled: true },
    }),
    [landUseTypes, inputName],
  );

  return landUseChangeInput
}

export default useLandUseTypeInput