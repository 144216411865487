import { Outlet, useOutletContext, useParams } from "react-router-dom";
import { useMemo } from "react";
import { useCustomTranslation } from "../../app/hooks";
import { useHandleActivityState } from "../activityBuilder/useHandleActivityState";
import Sidebar from "../../components/sidebar/Sidebar";
import BuilderTopbar from "../../components/topbar/BuilderTopbar";

type ContextType = { id: number | null };

const EditActivityContainer = () => {
	const params = useParams<string>();
	const { activityId } = params;
	const { activity, isFetching } = useHandleActivityState();
	const id = useMemo(() => (activityId ? +activityId : null), [activityId]);
	const translatedLoading = useCustomTranslation('main.loading');
	const translatedAct = useCustomTranslation("main.activity");

	return (
		<div className="edit-activity-container actions-close">
			<Sidebar />
			<BuilderTopbar
				activityLabel={isFetching ? `${translatedLoading}...` : `${translatedAct} ${activity?.id}.`}
				projectLabel={activity?.name}
				stepsList={[]}
			/>
			<div className="builder-main">
				<Outlet context={{ id } satisfies ContextType} />
			</div>
		</div >
	);
};

export function useId() {
	return useOutletContext<ContextType>();
}

export default EditActivityContainer;
