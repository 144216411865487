import  { useState } from 'react'
import { useAppSelector } from '../../../app/hooks';
import { selectCurrentProject } from '../../../app/features/project/projectSlice';
import { useDeleteActivityMutation } from '../../../app/features/activities/activityApiSlice';
import useErrorMessage from '../../../utils/useErrorMessage';
import { EventTypes, useFirebaseHook } from '../../../utils/firebaseUtils';
export const useDeleteActivity = (refetch: () => void) => {
	const { project } = useAppSelector(selectCurrentProject);
	const { fireEvent } = useFirebaseHook();
	const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
	const [toDeleteId, setToDeleteId] = useState<number | null>(null);
	const [deleteActivity, { isLoading: isDeleteLoading, isError, error }] =
	useDeleteActivityMutation();

	const { errorMsg } = useErrorMessage({ error, isError });

	const handleCancel = () => {
		setDeleteDialogOpen(false)
	}
    const handleDelete = async () => {
        if (isDeleteLoading || !toDeleteId) return;
        await deleteActivity({
            id: toDeleteId,
            project_id: project?.id ?? 0,
        });
        fireEvent(EventTypes.delete_activity);
        refetch();
        setDeleteDialogOpen(false)
    }

	const showDeleteModal = (id: number) => {
		setDeleteDialogOpen(true)
		setToDeleteId(id)
	}

    return {
        isDeleteDialogOpen,
        handleCancel,
        handleDelete,
        showDeleteModal,
        isDeleteLoading,
        deleteErrorMsg: errorMsg,
        isDeleteError: isError,
    }

}
