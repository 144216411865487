import { motion } from "framer-motion";
import { useAppSelector, useCustomTranslation } from "../../app/hooks";
import {
	selectCurrentBuilder,
} from "../../app/features/builder/builderSlice";
import { Outlet } from "react-router-dom";
import { Actions } from "../../types/interfaces";

import BuilderActions from "../../components/builderActions/BuilderActions";
import BuilderActionsTabs from "../../components/builderActions/BuilderActionsTabs";
import Sidebar from "../../components/sidebar/Sidebar";
import BuilderTopbar from "../../components/topbar/BuilderTopbar";
import BuilderMain from "../../components/main/BuilderMain";
import BuilderMainTabs from "../../components/main/BuilderMainTabs";
import Spinner from "../../components/spinner/Spinner";
import { useHandleActivityState } from "./useHandleActivityState";
import { selectCurrentProject } from "../../app/features/project/projectSlice";

const ActivityBuilder = () => {
	const {
		activeAction: activeTabId,
		moduleList,
		tabsOpen,
	} = useAppSelector(selectCurrentBuilder);
	const translatedActivity = useCustomTranslation("main.activity");
	const actionsGridClass = !tabsOpen
		? "actions-close"
		: [
			Actions.Comments,
			Actions.Help,
			Actions.Changes,
			Actions.Definitions,
		].includes(activeTabId)
			? "actions-small"
			: "";

	const { activity, isLoading } = useHandleActivityState();
	const { project } = useAppSelector(selectCurrentProject);

	return (
		<motion.div className={`builder-container ${actionsGridClass}`}>
			<Sidebar />
			<BuilderTopbar
				activityLabel={`${translatedActivity} ${activity?.id}.`}
				projectLabel={activity?.name}
				stepsList={moduleList}
				isLoading={isLoading}
			/>
			<BuilderActionsTabs />
			<BuilderMainTabs />
			<BuilderMain>
				{isLoading ? (
					<div className="p-3">
						<Spinner size={25} isBlack />
					</div>
				) : activity && project ? (
					<Outlet />
				) : (
					<div className="w-100 h-100 d-flex justify-content-center align-items-center">
						<p>Something went wrong</p>
					</div>
				)}
			</BuilderMain>
			<BuilderActions />
		</motion.div>
	);
};

export default ActivityBuilder;
