import FormRow from "../formRow/FormRow";
import CustomSelectInput from "../CustomSelectInput";

import { useEffect, useState, FC, useCallback } from "react";
import { Field, getIn, useFormikContext } from "formik";
import { CalendarYearSelectGroup, DropdownOptions, Option } from "../../../types/modulesInterfaces";
import { useAppSelector, useCustomTranslation } from "../../../app/hooks";
import { selectIsFetchingData } from "../../../app/features/builder/builderSlice";

const CalendarYearSelect: FC<CalendarYearSelectGroup> = ({
  inputName,
  disabled,
  label,
  index,
  comment,
  help,
  autosubmit,
  onChange,
  startYear,
  endYear
}) => {
  const [name, setName] = useState<string>("");
  const [options, setOptions] = useState<DropdownOptions>([]);

  const isFetchingData: boolean = useAppSelector(selectIsFetchingData);
  const translatedLabel = useCustomTranslation(label);
  const { errors, touched } = useFormikContext();

  useEffect(() => {
    if (!startYear || !endYear) return;
    const years: Option[] = Array.from({ length: endYear - startYear + 1 }, (_, i) => startYear + i).map((year) => ({ id: year, name: year.toString() }));
    setOptions(years);
  }, [startYear, endYear]);

  useEffect(() => {
    setName(
      typeof inputName === "function" ? inputName(index ?? 0) : inputName,
    );

    return () => {
      setName("");
    };
  }, [inputName, index]);


  return (
    <FormRow
      rowComment={comment}
      rowHelp={help}
      isFetching={isFetchingData}
      error={
        getIn(errors, name) && getIn(touched, name)
          ? getIn(errors, name)
          : undefined
      }
    >
      <Field
        label={translatedLabel}
        name={name}
        id={name}
        component={CustomSelectInput}
        options={options}
        disabled={disabled}
        autosubmit={autosubmit}
        onChange={onChange}
      />
    </FormRow>
  );
};

export default CalendarYearSelect;
