import type { SetupAssistantForm } from "../setupAssistant/setupAssistant.types";
import { useMemo } from "react";
import { useFormMachine } from "./setupAssistantMachine";
import { getModule, getNext } from "./setupAssistantUtils";
import SetupAssistantFinish from "./SetupAssistantFinish";
import SetupAssistantWizard from "./SetupAssistantWizard";
import TranslatableText from "../../../../components/translations/TranslatableText";
import Spinner from "../../../../components/spinner/Spinner";
import useErrorMessage from "../../../../utils/useErrorMessage";

const SetupAssistantContainer = () => {
	const machineProps = useFormMachine();
	const { schema, goNext, goBack, currentState, isFinal, modules, values, isError,errorMsg } =
		machineProps;

	const handleNext = (stepValues: SetupAssistantForm) => {
		const completeValues: SetupAssistantForm = { ...values, ...stepValues };
		const next = getNext(activeSubmodule?.step, completeValues); //for conditional events
		const modules = getModule(activeSubmodule?.step, completeValues); //to add modules to the list
		goNext({ values: stepValues, next, modules });
	};
	const handleReturnToWizard = () => {
		goBack({ values });
	}
	const activeSubmodule = useMemo(() => {
		return schema.find((sub) => sub.step === currentState);
	}, [currentState, schema]);

	return (
		<section>
			<div className="module-group">
				<div className={`pos-relative pb-1 ${isFinal ? "mb-2" : ""}`}>
					<h2 className="fs-14 ff-medium-ext module-header w-fit">
						<TranslatableText translationKey="activity.setup_assistant_title" />
					</h2>
					<div className="module-number header-number ff-light-ext">2.1</div>
				</div>

				<div className="d-flex f-column h-100" style={{ maxWidth: "450px" }}>
					{isFinal ? (
						<SetupAssistantFinish modules={modules} values={values} onReturn={handleReturnToWizard} />
					) : machineProps.schema.length === 0 && !isError
						? (
							<Spinner size={28} padding={2} isBlack />
						)
						: (
							<SetupAssistantWizard
								handleNext={handleNext}
								machineProps={machineProps}
							/>
						)}
				</div>
				{
					isError && (
						<p className="text-error fs-13">
							{errorMsg}
						</p>
					)
				}
			</div>
		</section>
	);
};

export default SetupAssistantContainer;
