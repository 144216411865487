import React from 'react'
import TranslatableText from '../../../components/translations/TranslatableText'
import { TranslationKey } from '../../../types/modulesInterfaces'

const ProjectDescriptionSubtitle = ({translationKey}: {translationKey: TranslationKey}) => {
  return (
    <p className="fs-14 mt-2">
        <TranslatableText translationKey={translationKey} />
    </p>
  )
}

export default ProjectDescriptionSubtitle