import { AnyObject } from "yup/lib/types"
import { BEModules } from "../../../../../utils/moduleList"
import * as Yup from "yup";
import type {
    ModuleFull,
} from "../forestManagementTypes";
import { messages } from "./messages";
import { standardLucValidation, validatePercentage, validatePositiveNumber, validateStrictPercentage } from "../../moduleUtils";
import { BelongsToLuc, TranslationKey } from "../../../../../types/modulesInterfaces";

type Scenario = 'start' | 'w' | 'wo'
abstract class Validation {
    moduleFull: ModuleFull | null = null
    scenario: Scenario
    constructor(contex: Yup.TestContext<AnyObject>, scenario: Scenario) {
        this.initialize(contex);
        this.scenario = scenario
    }
    protected initialize(contex: Yup.TestContext<AnyObject>) {
        const unsafeContext = contex as any;
        const moduleFull: ModuleFull = unsafeContext.from[1]?.value;
        this.moduleFull = moduleFull ?? null;
    }
    validate(value: number | undefined | null) {
        if (value === null || value === undefined) return true
        return this.validateSpecificConditios(this.scenario)
    }

    validateSpecific() {
        return this.validateSpecificConditios(this.scenario)
    }

    noLoggings() {
        if (!this.moduleFull) return true
        const parent = this.moduleFull.parent
        return !parent[`logging_recurrence_yrs_${this.scenario}`] && !parent[`logging_percentage_agb_logged_${this.scenario}`] && !parent[`logging_percentage_biomass_for_energy_${this.scenario}`]
    }
    noRotations(): boolean {
        if (!this.moduleFull) return true
        const parent = this.moduleFull.parent
        return !parent[`rotation_length_yrs_${this.scenario}`] && !parent[`rotation_percentage_biomass_for_energy_${this.scenario}`]
    }
    noDegradations(): boolean {
        if (!this.moduleFull) return true
        const parent = this.moduleFull.parent
        return !parent[`average_yearly_degradation_percentage_${this.scenario}`]
    }
    noDisturbances(): boolean {
        if (!this.moduleFull) return true
        const recurrenceDisturbance = this.moduleFull.disturbances.find((dist) => dist[`recurrence_yrs_${this.scenario}`])
        const biomassDisturbance = this.moduleFull.disturbances.find((dist) => dist[`percentage_biomass_destruction_${this.scenario}`])
        return !recurrenceDisturbance?.[`recurrence_yrs_${this.scenario}`] && !biomassDisturbance?.[`percentage_biomass_destruction_${this.scenario}`]
    }
    abstract validateSpecificConditios(scenario: Scenario): boolean
}

class DisturbanceValidation extends Validation {
    validateSpecificConditios(scenario: Scenario): boolean {
        return this.noRotations() && this.noDegradations()
    }
}

class RotationValidation extends Validation {
    validateSpecificConditios(): boolean {
        return this.noLoggings() && this.noDegradations() && this.noDisturbances()
    }
}

class LoggingValidation extends Validation {
    validateSpecificConditios(): boolean {
        return this.noRotations() && this.noDegradations()
    }
}

class DegradationValidation extends Validation {
    validateSpecificConditios(): boolean {
        return this.noLoggings() && this.noDisturbances() && this.noRotations()
    }
}
type GenericValidation = typeof DisturbanceValidation | typeof RotationValidation | typeof LoggingValidation | typeof DegradationValidation

function createValidation(
    scenario: Scenario,
    field: keyof typeof messages,
    isPercentage: boolean,
    GenericValidation: GenericValidation,
    belongsToLuc: BelongsToLuc | undefined,
    isStrictPercentage = false,
) {
    const validation = isStrictPercentage ? validateStrictPercentage : isPercentage ? validatePercentage : validatePositiveNumber

    return standardLucValidation(messages[field].requiredMessage as unknown as TranslationKey, scenario, BEModules.ForestManagement, validation, belongsToLuc)
        .test(`${field}_${scenario}`, messages[field].conditionsMessage, function (value) {
            if (!value) return true
            const validator = new GenericValidation(this, scenario)
            return validator.validateSpecific()
        })
}



export function createdDisturbanceValidation(belongsToLuc: BelongsToLuc | undefined) {
    return (scenario: Scenario, field: keyof typeof messages, isPercentage = false) => {
        return createValidation(scenario, field, isPercentage, DisturbanceValidation, belongsToLuc, isPercentage ? true : false)
    }
}
export function createRotationValidation(belongsToLuc: BelongsToLuc | undefined) {
    return (scenario: Scenario, field: keyof typeof messages, isPercentage = false) => {
        return createValidation(scenario, field, isPercentage, RotationValidation, belongsToLuc)
    }
}

export function createLoggingValidation(belongsToLuc: BelongsToLuc | undefined) {
    return (scenario: Scenario, field: keyof typeof messages, isPercentage = false) => {
        return createValidation(scenario, field, isPercentage, LoggingValidation, belongsToLuc)
    }
}

export function createDegradationValidation(belongsToLuc: BelongsToLuc | undefined) {
    return (scenario: Scenario, field: keyof typeof messages, isPercentage = false) => {
        return createValidation(scenario, field, isPercentage, DegradationValidation, belongsToLuc)
    }
}