import { useFormikContext } from 'formik'
import React, { useCallback, useEffect } from 'react'
import { editActivity, EditActivityFields, isClimateInput, isMoistureInput } from './utils'
import { getCapitalizationYears } from '../../utils/projectUtils'
import { ActivityUpdatePayload } from '../../app/features/activities/activityTypes'
import GenericInputGroup from '../../components/input/inputGroup/GenericInputGroup'
import { useAppSelector, useClimateAndMoistureFormHandlers } from '../../app/hooks'
import { selectCurrentProject } from '../../app/features/project/projectSlice'

interface EditActivityContentProps {
  isLoading: boolean
}
const EditActivityContent: React.FC<EditActivityContentProps> = ({ isLoading }) => {
  const { project } = useAppSelector(selectCurrentProject);
  const { values, setFieldValue } = useFormikContext<ActivityUpdatePayload>()
  const { handleClimateInputChange, getMoistureOptions } = useClimateAndMoistureFormHandlers();

  useEffect(() => {
    const captalizationPhase = getCapitalizationYears(values.activity.start_year_t2, values.activity.duration_t2, project?.last_year_of_accounting)
    setFieldValue(EditActivityFields.capitalization_years, captalizationPhase)
  }, [values]);

  return (
    <div>
      {editActivity.inputGroups.map((input, index) => {
        if (isMoistureInput(input)) {
          input.dropdownOptions = getMoistureOptions(values.activity.climate_t2)
        }
        if (isClimateInput(input)) {
          input.onChange = (e: any) => {
            handleClimateInputChange(e, values.activity.moisture_t2, EditActivityFields.moisture_t2)
          }
        }
        return (
          <div className="py-1" key={`${input.label}-${index}`}>
            <GenericInputGroup
              inputGroup={{ ...input, disabled: isLoading || input.disabled }}
            />
          </div>
        )
      })}
    </div>
  )
}

export default EditActivityContent