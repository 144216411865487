import { useNavigate } from "react-router-dom";
import { getModuleNameFromBEId } from "../../../utils/moduleList";
import { FaArrowRight, FaCubes } from "react-icons/fa";
import { BiEditAlt, BiPlusCircle, BiTrash } from "react-icons/bi";
import { MdContentCopy } from "react-icons/md";
import Button from "../../../components/button/Button";
import KebabMenu from "../../../components/kebabMenu/KebabMenu";
import type { Line } from "../../../components/kebabMenu/KebabMenu";
import type { FastActivity } from "../../../types/interfaces";
import { useMemo } from "react";
import { useCreateActivityCopyMutation, } from "../../../app/features/activities/activityApiSlice";
import { motion } from "framer-motion";
import TranslatableText from "../../../components/translations/TranslatableText";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { selectCurrentProject, setActivity } from "../../../app/features/project/projectSlice";
import { setModuleList } from "../../../app/features/builder/builderSlice";
import { EventTypes, useFirebaseHook } from "../../../utils/firebaseUtils";

const ActivityCard = ({
	activity,
	onDelete,
	canEdit
}: {
	activity: FastActivity;
	onDelete: () => void;
	canEdit: boolean;
}) => {
	const { activity: currentActivity } = useAppSelector(selectCurrentProject)
	const { fireEvent } = useFirebaseHook();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const [duplicateActivity, { isLoading: isDuplicateLoading }] =
		useCreateActivityCopyMutation();

	const handleCardClick = () => {
		navigate(`/builder/${activity.id}`)
		if (activity.id !== currentActivity?.id)
			dispatch(setModuleList([]))
	}

	const progress = useMemo(() => {
		const completition = activity?.completion_percentage ?? 0;
		return completition * 100 < 5 ? 5 : completition * 100;
	}, [activity.completion_percentage]);

	const isEmpty = useMemo(
		() => activity?.completion_percentage === 0,
		[activity.completion_percentage],
	);
	const lines: Line[] = useMemo(
		(): Line[] => [
			{
				id: 0,
				label: "main.edit",
				icon: BiEditAlt,
				clickHandler: () => navigate(`/activity/${activity.id}/edit`),
			},
			{
				id: 1,
				label: "main.duplicate",
				icon: MdContentCopy,
				clickHandler: async () => {
					if (isDuplicateLoading) return;
					duplicateActivity(activity.id).unwrap().then(() => fireEvent(EventTypes.duplicate_activity));
				},
			},
			{
				id: 3,
				label: "main.delete",
				icon: BiTrash,
				clickHandler: onDelete,
			},
		],
		[onDelete, activity.id],
	);

	return (
		<motion.div
			className="activity-card"
			whileHover={{ boxShadow: "5px 5px 10px rgba(73, 28, 105, 0.3)" }}
		>
			<div className="content">
				<div className="d-flex f-column">
					<span className="fs-11 text-gray lh-90"><TranslatableText translationKey="main.activity" /> {activity.id}</span>
					<h3 className="fs-11 ff-medium text-common-dark">{activity.name}</h3>
				</div>

				{canEdit ? <KebabMenu lines={lines} activeColor="var(--quaternary-dark)" /> : <div></div>}
				<div className="d-flex f-column">
					<div className="fs-11 text-gray" >
						<TranslatableText translationKey="main.modules" /> :
					</div>
					<ul>
						{activity.module_types.map((mod, j) => (
							<li
								className="ff-normal-cond fs-11"
								style={{ paddingLeft: "0.2rem" }}
								key={j}
							>
								<TranslatableText translationKey={getModuleNameFromBEId(mod)} />
							</li>
						))}
					</ul>
				</div>
				<div>
					<FaCubes color="var(--gray)" size={28} />
				</div>
			</div>
			<Button
				classes={"footer"}
				whileHoverProps={{ scale: 1, opacity: 0.9 }}
				whileTapProps={{ scale: 1, opacity: 0.95 }}
				style={{
					borderImage: `linear-gradient(90deg, #b53f74 ${progress}%, white 0%, white 100%) 1`,
				}}
				onClick={handleCardClick}
			>
				<div>
					<FaArrowRight color="white" />
				</div>
				<TranslatableText className="fs-11 ff-medium d-flex align-items-center ps-1 h-100" translationKey={isEmpty ? "activity.perform_assessment" : "activity.edit_assessment"} />
			</Button>
		</motion.div>
	);
};

export const CreateNewActivityCard = () => {
	const navigate = useNavigate();
	const handleNewClick = () => {
		navigate("../new-activity");
	};

	return (
		<div
			className="activity-card justify-content-between"
			style={{ height: "108px !important" }}
		>
			<div className="content">
				<h3 className="fs-11 ff-medium text-common-dark">
					<TranslatableText translationKey="activity.create_new_activity" />
				</h3>
				<div />
				<div />
				<div>
					<FaCubes color="var(--gray)" size={28} />
				</div>
			</div>

			<Button
				classes="footer create justify-content-between"
				whileHoverProps={{ scale: 1, opacity: 0.9 }}
				whileTapProps={{ scale: 1, opacity: 0.95 }}
				style={{
					borderImage:
						"linear-gradient(90deg, var(--quaternary-dark) 10%, white 0%, white 100%) 1",
				}}
				onClick={handleNewClick}
			>
				<TranslatableText className="fs-11 ff-medium d-flex align-items-center ps-1 h-100" translationKey="main.create_new" />
				<div>
					<BiPlusCircle size={18} color="white" />
				</div>
			</Button>
		</div>
	);
};

export default ActivityCard;
